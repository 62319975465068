<template>
  <div class="container_margin">
    <!-- start of Court & Institution modal -->
    <b-modal id="court-modal"  centered hide-footer hide-header size="xl">
      <!-- Header section -->
      <div class="row">
        <div class="col-12" v-if="juriNCourts[selectedIndex]?.jurisdiction === 'United States of America'">
          <div class="mb-3">
            <b>Chosen State(s):</b>
            <div
              v-for="(court, c_index) in selectedCourt"
              :key="c_index"
              class="d-inline-block me-2"
            >
              <span
                class="court_badge cursor-pointer"
                @click="removeModalCourt(court)"
              >
                {{
                  court == "all" + juriNCourts[selectedIndex].jurisdiction
                    ? "All Courts and Institutions in " +
                      juriNCourts[selectedIndex].jurisdiction
                    : court
                }}
                <span class="material-icons-outlined">close</span>
              </span>
            </div>
            <b-form-select
              class="form-select mt-3"
              v-model="selectedState"
              :options="states"
              value-field="id"
              text-field="name"
              placeholder="Select State"
            ></b-form-select>
          </div>
        </div>

        <div class="col-12" v-if="juriNCourts[selectedIndex]?.jurisdiction !== 'United States of America'">
          <div class="mb-3">
            <b>Chosen Court(s):</b>
            <div
              v-for="(court, c_index) in selectedCourt"
              :key="c_index"
              class="d-inline-block me-2"
            >
              <span
                class="court_badge cursor-pointer"
                @click="removeModalCourt(court)"
              >
                {{
                  court == "all" + juriNCourts[selectedIndex].jurisdiction
                    ? "All Courts and Institutions in " +
                      juriNCourts[selectedIndex].jurisdiction
                    : court
                }}
                <span class="material-icons-outlined">close</span>
              </span>
            </div>
              <b-form-input
                class="mt-3"
                placeholder="Search Court"
                v-model="searchCourtsQuery"
                autocorrect="off"
                autocomplete="off"
              ></b-form-input>
          </div>
        </div>
      </div>

      <!-- Court List section -->
      <h5 class="mt-4">
        <b>{{
          "Courts and Institutions in " +
          juriNCourts[selectedIndex]?.jurisdiction
        }}</b>
      </h5>

      <div class="row court-list">
        <div class="col">
          <b-form-checkbox
            v-if="searchCourtsQuery.length == 0 && !sort_alp"
            id="allCourts"
            class="my-2"
            name="allCourts"
            :checked="
              juriNCourts[selectedIndex]?.courts[0] ==
              'all' + juriNCourts[selectedIndex]?.jurisdiction
            "
            @change="handleChooseAll"
          >
            All Courts & Institutions
          </b-form-checkbox>
          <b-form-checkbox
            v-else
            id="allStateCourts"
            name="allCourts"
            :checked="searchCourtsQuery"
            @change="handleChooseAllStates"
          >
            {{ "All Courts & Institutions in " + searchCourtsQuery}}
          </b-form-checkbox>

          <div
            v-for="(cat, c_index) in courtlist.courts"
            :key="c_index"
          >
            <h6
              v-if="searchCourtsQuery.length == 0 && !sort_alp"
              class="my-2"
            >
              <b>{{ cat.hierarchy | capitalize }}</b>
            </h6>

            <b-form-checkbox
              class="font-16 cursor-pointer"
              v-for="(court, ct_index) in cat.courts"
              :key="ct_index"
              v-show="
                (searchCourtsQuery.length > 0
                  ? court.name
                      ?.toLowerCase()
                      .includes(searchCourtsQuery.toLowerCase())
                  : true) &&
                (court.name[0].toLowerCase() == sort_alp?.toLowerCase() ||
                  !sort_alp)
              "
              :disabled="
                ((juriNCourts[selectedIndex]?.jurisdiction !== 'United States of America' && selectedCourt.length > 1) ||
                  (selectedCourt[0] == 'all' + juriNCourts[selectedIndex]?.jurisdiction) || selectedCourt[0] == 'All Courts and Institutions in ' + searchCourtsQuery) &&
                !selectedCourt.includes(court.name)
              "
              v-model="selectedCourt"
              :value="court.name"
              @change="selectCourt(court)"
            >
              {{ court.name }}
            </b-form-checkbox>
          </div>
        </div>

        <div class="col-1">
          <span
            :class="[
              'cursor-pointer',
              sort_alp == null ? 'text-blue' : 'text-muted'
            ]"
            @click="sort_alp = null"
          >
            {{ "All" }}
          </span>
          <span
            :class="[
              'cursor-pointer d-block',
              sort_alp == alphabet ? 'text-blue' : 'text-muted'
            ]"
            v-for="(alphabet, a_index) in courtlist.Alphabet"
            :key="a_index"
            @click="sort_alp = alphabet"
          >
            {{ alphabet | capitalize }}
          </span>
        </div>
      </div>

      <button
        @click="saveCourts(true)"
        class="btn btn-primary float-right px-4 m-2"
      >
        {{ "Done" }}
      </button>
      <button
        @click="saveCourts(false)"
        class="btn btn-primary-outlined float-right px-4 m-2"
      >
        {{ "Cancel" }}
      </button>
    </b-modal>
    <!-- end of Court & Institution modal -->

    <!-- start of Search Form -->
    <div class="card p-3">
      <!-- start of Enter Search input -->
      <div>
        <b>
          {{ "Enter Search" }}
          <span
            id="tooltip-target-1"
            class="text-danger cursor-pointer"
          >*</span>
        </b>
        <b-tooltip
          target="tooltip-target-1"
          triggers="hover"
          placement="top"
          variant="secondary"
          id="tooltip1"
          title="Required"
        ></b-tooltip>

        <b-form-textarea
          id="textarea"
          class="mt-2"
          name="Search"
          v-model.trim="search"
          v-validate="'required'"
          :placeholder="'Search for Judgement\nExample 1: can second wife claim husband\'s insurance money?\nExample 2: driving in drunken state is a violation of law\nExample 3: alcohol, driver, accident\nExample 4: domestic violence\nExample 5: The Health and Social Care Department v AB & CD [2014] XYZ 1'"
          autocorrect="off"
          autocomplete="off"
          rows="5"
          max-rows="9"
        ></b-form-textarea>

        <small
          :class="getWordCount(search) <= 50 ? 'text-muted' : 'text-danger'"
          class="float-right count_length"
        >
          {{ getWordCount(search) }} / 50
        </small>

        <small v-show="getWordCount(search) > 50" class="text-danger ">
          Should be less than 50 words
        </small>
        <small class="text-danger ">
          {{ errors.first("Search") }}
        </small>
      </div>
      <!-- end of Enter Search input -->

      <!-- start of Jurisdiction & Court selection -->
      <div class="mb-3">
        <b>
          {{ "Jurisdictions & Courts" }}
          <span
            id="tooltip-target-2"
            class="text-danger cursor-pointer"
          >*</span>
        </b>
        <b-tooltip
          target="tooltip-target-2"
          triggers="hover"
          placement="top"
          variant="secondary"
          id="tooltip2"
          title="Required"
        ></b-tooltip>
        <small>
          <br>{{ "(Processing time may increase by several minutes, depending on the number of jurisdictions chosen)" }}
        </small>

        <div
          class="card p-2"
          v-for="(juri, index) in juriNCourts"
          :key="index"
        >
          <div class="row">
            <div class="col-5 d-flex align-items-start">
              <span style="margin-top: 7px" class="mx-2">
                {{ index + 1 }}.
              </span>
              <b-form-select
                id="JuriInputGroup"
                :class="[
                  'form-select',
                  { 'text-muted': !juri.jurisdiction },
                ]"
                name="Jurisdictions"
                v-model="juri.jurisdiction"
                @change="handleSelectJurisdiction(index)"
              >
                <b-form-select-option selected disabled :value="null">
                  {{ "Please Select a Country" }}
                </b-form-select-option>
                <b-form-select-option
                  class="text-softblack"
                  v-for="(country, c_index) in jurisdictionOption[index]"
                  :key="c_index"
                  :value="country"
                >
                  {{ country }}
                </b-form-select-option>
              </b-form-select>
            </div>

            <div class="col" @click="openCourtModal(index)">
              <div :class="[
                'd-flex justify-content-center h-100 card px-1',
                { 'cursor-pointer': juri.jurisdiction }
              ]">
                <p
                  class="text-muted my-0 ms-1"
                  v-if="juri.courts.length == 0"
                >
                  {{
                    !juri.jurisdiction
                      ? "Please Select a Jurisdiction"
                      : "Click to Select Court"
                  }}
                </p>

                <span
                  v-else-if="juri?.courts[0] == 'all' + juri.jurisdiction"
                  class="court_badge cursor-pointer my-1"
                  @click.stop="removeCourt(juri?.courts[0], index)"
                >
                  {{
                    "All Courts and Institutions in " + juri.jurisdiction
                  }}
                  <span class="material-icons-outlined">close</span>
                </span>

                <span
                  v-else
                  class="d-inline court_badge coursor-pointer my-1 mx-1"
                  v-for="(court, c_index) in juri?.courts"
                  :key="c_index"
                  @click.stop="removeCourt(court, index)"
                >
                  {{ court.name }}
                  <span class="material-icons-outlined">close</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end of Jurisdiction & Court selection -->

      <span>
        <button @click="clear_search" class="btn btn-primary-outlined float-right">
          {{ "Clear Search" }}
        </button>
        <button @click="search_result" class="btn btn-primary float-right me-3">
          {{ "Search" }}
        </button>
      </span>
    </div>
    <!-- end of Search Form -->

    <!-- start of Search Result header -->
    <h5 class="mt-3">
      <b>{{ `Search Result${ searched_data.length > 1 ? "s" : "" }` }}</b>
    </h5>

    <div class="row mt-2 mb-3">
      <div
        v-if="searched_data.length !== 0 || searchQuery.length !== 0"
        class="col-5"
      >
        <span id="searchCaseLaw" class="input-group">
          <input
            v-model="searchQuery"
            type="search"
            class="form-control"
            placeholder="Search by statement"
            aria-label="Search by statement"
            @input="onInput"
            @keyup.enter="searchClients"
          />
          <span
            class="input-group-text cursor-pointer"
            @click="searchClients"
          >
            <b-icon icon="search" font-scale="1.2" />
          </span>
        </span>
      </div>

      <div v-if="searched_data.length !== 0 && rows > 10" class="col-7">
        <b-pagination
          class="mb-0 float-right"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          last-number
        ></b-pagination>
      </div>
    </div>
    <!-- end of Search Result header -->

    <!-- start of Search Results -->
    <table class="table card border shadow-sm p-2 rounded table-card">
      <thead>
        <tr>
          <th scope="col" width="6%">{{ "ID" }}</th>
          <th scope="col" width="30%">{{ "Statement" }}</th>
          <th scope="col" width="18%">{{ "Jurisdiction(s)" }}</th>
          <th scope="col" width="12%" class="text-center">
            {{ "Created Date" }}
          </th>
          <th scope="col" width="12%" class="text-center">
            {{ "Created By" }}
          </th>
          <th scope="col" width="10%" class="text-center">
            {{ "Findings" }}
          </th>
          <th
            class="text-center"
            scope="col"
            width="15%"
            style="padding-right: 25px"
            v-if="permissions.includes('nexlaw.jurisdiction-show')"
          >
            {{ "Action" }}
          </th>
        </tr>
      </thead>

      <tbody>
        <PageLoader
          class="w-100"
          v-if="pageLoading"
          :loading="pageLoading"
        />

        <div
          v-else-if="searched_data.length === 0"
          class="container_margin"
        >
          <div class="my-2 m-auto" style="width: 200px">
            <img src="@/assets/noData.png" alt="No Data Image" />
          </div>
          <p class="text-center text-blue font-weight-bold">
            {{ "No Data, Kindly start your search" }}
          </p>
        </div>

        <tr v-else v-for="(item, index) in searched_data" :key="index">
          <td width="6%">{{ item.id }}</td>
          <td width="30%"><p>{{ item.statement }}</p></td>
          <td width="18%">
            <p>
              {{
                item.jurisdictions
                  ? getJurisdictionKey(item.jurisdictions).join(", ")
                  : "N/A"
              }}
            </p>
          </td>
          <td width="12%">
            <p class="text-muted text-center">
              {{ item.created_at | formatDateLoc }}
            </p>
          </td>
          <td width="12%" class="text-center">
            <p class="text-muted font-weight-bold">
              {{ item.creator.name | username }}
            </p>
          </td>
          <td width="10%" class="text-center">
            <p class="text-center">{{ item.total_findings }}</p>
          </td>
          <td
            width="15%"
            class="text-center"
            v-if="permissions.includes('nexlaw.jurisdiction-show')"
          >
            <button
              v-if="
                item.completedPerCentage >= 100 ||
                item.completedPerCentage == 'Not enough data'
              "
              class="btn btn-primary"
              @click="openCase(item.id)"
            >
              {{ "Open" }}
            </button>

            <button
              v-else
              class="btn btn-warning-outlined"
              @click="$toast.warning('Still in progress')"
            >
              {{ item.completedPerCentage.toFixed(2) }} %
              <b-spinner label="Spinning" class="spinner" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- end of Search Results -->

    <div style="height: 2.5rem;">
      <b-pagination
        v-if="searched_data.length !== 0 && rows > 10"
        class="float-right mt-3"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
        last-number
      ></b-pagination>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import PageLoader from "../../components/PageLoader.vue";
import Auth from "@/store/Auth.js";
import { courts } from "./courtnames.js";
import CaseSearch from "@/store/CaseSearch.js";
import { getWordCount, groupValidateInput, loopUpdate } from "../../store/utils.js";

export default {
  components: {
    PageLoader,
  },

  data() {
    return {
      selectedState: null,
      states: [
        { id: null, name: "Select State", disabled: true },
        { id: 'All', name: 'All Courts' },
        { id: '1', name: 'Alabama' },
        { id: '2', name: 'Alaska' },
        { id: '3', name: 'Arizona' },
        { id: '4', name: 'Arkansas' },
        { id: '5', name: 'California' },
        { id: '6', name: 'Colorado' },
        { id: '7', name: 'Connecticut' },
        { id: '8', name: 'Delaware' },
        { id: '9', name: 'Florida' },
        { id: '10', name: 'Georgia' },
        { id: '11', name: 'Hawaii' },
        { id: '12', name: 'Idaho' },
        { id: '13', name: 'Illinois' },
        { id: '14', name: 'Indiana' },
        { id: '15', name: 'Iowa' },
        { id: '16', name: 'Kansas' },
        { id: '17', name: 'Kentucky' },
        { id: '18', name: 'Louisiana' },
        { id: '19', name: 'Maine' },
        { id: '20', name: 'Maryland' },
        { id: '21', name: 'Massachusetts' },
        { id: '22', name: 'Michigan' },
        { id: '23', name: 'Minnesota' },
        { id: '24', name: 'Mississippi' },
        { id: '25', name: 'Missouri' },
        { id: '26', name: 'Montana' },
        { id: '27', name: 'Nebraska' },
        { id: '28', name: 'Nevada' },
        { id: '29', name: 'New Hampshire' },
        { id: '30', name: 'New Jersey' },
        { id: '31', name: 'New Mexico' },
        { id: '32', name: 'New York' },
        { id: '33', name: 'North Carolina' },
        { id: '34', name: 'North Dakota' },
        { id: '35', name: 'Ohio' },
        { id: '36', name: 'Oklahoma' },
        { id: '37', name: 'Oregon' },
        { id: '38', name: 'Pennsylvania' },
        { id: '39', name: 'Rhode Island' },
        { id: '40', name: 'South Carolina' },
        { id: '41', name: 'South Dakota' },
        { id: '42', name: 'Tennessee' },
        { id: '43', name: 'Texas' },
        { id: '44', name: 'Utah' },
        { id: '45', name: 'Vermont' },
        { id: '46', name: 'Virginia' },
        { id: '47', name: 'Washington' },
        { id: '48', name: 'West Virginia' },
        { id: '49', name: 'Wisconsin' },
        { id: '50', name: 'Wyoming' }
      ], 

      juri_number: 1,
      selectedIndex: null,
      selectedCourt: [],
      selectedCourtObj: [],

      searchCourtsQuery: "",
      sort_alp: null,
      courtlist: [],

      juriNCourts: [
        {
          jurisdiction: null,
          courts: [],
        },
      ],

      permissions: localStorage.permissions,
      rows: 100,
      perPage: 10,
      currentPage: 1,
      pageLoading: true,
      status: "accepted",
      search: "", // Input for the search query
      filter: "", // Input for the filter query

      searched_data: [], // Array to hold the search results
      filter: ["Title", "Decision Date", "Court Name"],
      chosenFilter: "",
      searchQuery: "",

      loop: false,
    };
  },

  computed: {
    jurisdictionOption() {
      const option = [
        "United States of America",
        "United Kingdom",
        "Australia",
        "Canada",
        "New Zealand",
        "Singapore",
        "Malaysia",
      ];

      const array = [];
      for (let i = 0; i < option.length; i++) {
        array.push(
          option.filter(
            (juri) =>
              !this.juriNCourts
                .map((data) => data.jurisdiction)
                .includes(juri) || this.juriNCourts[i]?.jurisdiction == juri
          )
        );
      }
      return array;
    },
  },

  methods: {
    getWordCount(text) { return getWordCount(text); },

    getJurisdictionKey(jurisdiction) {
      const countryName = Object.keys(JSON.parse(jurisdiction));

      return countryName.map((country) => {
        if (country === "United States") {
          return "United States of America";
        }
        return country;
      });
    },

    handleSelectJurisdiction(index) {
      this.juriNCourts[index].courts = [];
      this.openCourtModal(index);
    },

    updateJuriNumber() {
      if (this.juri_number > 7 || this.juri_number < 1) {
        this.$toast.error("Please input number within range from 1 to 7.");
        this.juri_number = this.juri_number > 7 ? 7 : 1;
      }

      const jcLength = this.juriNCourts.length;
      if (jcLength < this.juri_number) {
        for (let i = 0; i < this.juri_number - jcLength; i++) {
          this.juriNCourts.push({
            jurisdiction: null,
            courts: [],
          });
        }
      } else if (jcLength > this.juri_number) {
        this.juriNCourts.splice(this.juri_number);
      }
    },

    removeCourt(court, index) {
      this.juriNCourts[index].courts = this.juriNCourts[index].courts.filter(
        (crt) => crt.name !== court.name
      );
    },

    openCourtModal(index) {
      const data = this.juriNCourts[index];
      this.selectedCourt = [];
      if (!data.jurisdiction) return;
      this.selectedCourt = []; 
      this.selectedCourtObj = [...data.courts];
      this.selectedCourt = [
        ...data.courts.map((crt) => {
          return crt?.[0] == "a" ?? false ? crt : crt.name;
        }),
      ];

      this.courtlist = courts[data.jurisdiction];
      this.selectedIndex = index;
      this.$bvModal.show("court-modal");
    },

    removeModalCourt(court) {
      this.selectedCourtObj = this.selectedCourtObj.filter(
        (crt) => crt.name !== court
      );
      this.selectedCourt = this.selectedCourtObj.map((crt) => crt.name);
    },

    handleChooseAll() {
      const ALL = "all" + this.juriNCourts[this.selectedIndex].jurisdiction;

      if (this.selectedCourtObj[0] == ALL) {
        this.$set(this, "selectedCourt", []);
        this.$set(this, "selectedCourtObj", []);
      } else {
        this.$set(this, "selectedCourt", [ALL]);
        this.$set(this, "selectedCourtObj", [ALL]);
      }
    },

    handleChooseAllStates() {
      const STATE = 'All Courts and Institutions in ' + this.searchCourtsQuery
      const arr = [];
      this.courtlist.courts.forEach((item) => {
        item = item.courts.filter((crt) => crt.name?.toLowerCase().includes(this.searchCourtsQuery.toLowerCase()));
        arr.push(...item);
      });
      
      if (this.compareArray(this.selectedCourtObj, arr)) {
        this.$set(this, "selectedCourt", []);
        this.$set(this, "selectedCourtObj", []);
      } else {
        this.$set(this, "selectedCourt", [STATE]);
        this.$set(this, "selectedCourtObj", arr);
      }
      // console.log(this.selectedCourtObj);
    },

    compareArray(a, b) {
      return a.length === b.length && a.every((element, index) => element === b[index]);
    },

    selectCourt(court) {
      if (this.selectedCourtObj.includes(court)) {
        this.selectedCourtObj = this.selectedCourtObj.filter(
          (crt) => crt !== court
        );
      } else {
        this.selectedCourtObj.push(court);
      }
    },

    saveCourts(flag) {
      if (flag) {
        this.juriNCourts[this.selectedIndex].courts = [
          ...this.selectedCourtObj,
        ];

        if (
          this.selectedIndex < 6 &&
          this.juri_number == this.selectedIndex + 1 &&
          this.selectedCourt.length > 0
        ) {
          this.juri_number = this.selectedIndex + 2;
          this.updateJuriNumber();
        }
      }

      this.clearModal();
      this.$bvModal.hide("court-modal");
    },

    clearModal() {
      this.selectedCourt = [];
      this.selectedCourtObj = [];
      this.selectedIndex = null;
      this.sort_alp = null;
    },

    onInput() {
      if (this.searchQuery.length === 0) {
        this.searchClients();
      }
    },

    searchClients() {
      this.currentPage = 1;
      this.pageLoading = true;
      this.searched_data = [];

      var param = `&page=${this.currentPage}`;
      var queryObj = { page: this.currentPage };
      if (this.searchQuery.length !== 0) {
        param += `&statement=${this.searchQuery}`;
        queryObj.statement = this.searchQuery;
      }

      this.$router.push({ query: queryObj });
      this.loadData();
    },

    // formatDate(date) {
    //   let d = new Date(date),
    //     month = `${d.getMonth() + 1}`,
    //     day = `${d.getDate()}`,
    //     year = d.getFullYear();
    //   if (month < 10) month = `0${month}`;
    //   if (day < 10) day = `0${day}`;
    //   return [year, month, day].join("-");
    // },

    clear_search() {
      this.emptyInputs();
      this.juriNCourts = [];
      this.juri_number = 1;
      this.updateJuriNumber();
    },

    emptyInputs() {
      this.search = "";
      this.juriNCourts = [];
      this.juri_number = 1;
      this.updateJuriNumber();
    },

    toastError(flag) {
      this.$toast.error({
        "empty": "Please fill in all required fields",
        "max": "Maximum word limit exceeded",
        "courts": "Please select court(s) for each chosen jurisdiction",
      }[flag]);
    },

    search_result() {
      this.$validator.validateAll();

      const Results = groupValidateInput([
        [this.search, "string", ["max50"]],
        [this.juriNCourts, "courts"],
      ]);
      
      for (let i = 0; i < Results.length; i++) {
        if (!Results[i][0]) {
          this.toastError(Results[i][1]);
          return;
        }
      }

      // // removed date input

      // // removed date input
      // switch (null) {
      //   case this.date_from:
      //   case this.date_to:
      //     this.$toast.error("Please fill in all the input");
      //     return;
      //   default:
      //     break;
      // }

      // const FDate = new Date(this.date_from);
      // const TDate = new Date(this.date_to);
      // const diffInYears = (TDate - FDate) / (1000 * 60 * 60 * 24 * 365.25);

      // if (diffInYears > 10) {
      //   this.$toast.error("Please choose a date range up to 10 years only");
      //   return;
      // }

      // formatting output
      // this.formatDate(this.date_from)
      // this.formatDate(this.date_to)

      var obj = {
        search_type: "and",
        jurisdictions: Results[1][1],
        statement: [Results[0][1]],
        jurisdictions: Results[1][1],
        statement: [Results[0][1]],
        location_id: 1,
        language: "en",
        from_date: "2024-05-05",
        to_date: "2024-09-05",
      };

      // console.log(obj); return;
      this.emptyInputs();
      this.pageLoading = true;
      Auth.getMe()
        .then((res) => {
          localStorage.setItem("userInfo", JSON.stringify(res.data.data));
          switch (res.data.data.nexlaw_credits) {
            case undefined:
            case "undefined":
            case null:
            case "null":
              localStorage.removeItem("accessToken");
              localStorage.removeItem("userInfo");
              this.$router.push("/login");
              break;
          }
        })
        .then(() => {
          return CaseSearch.caseSearch(obj);
        })
        .then((response) => {
          this.$toast.success(response.data.message);
          this.currentPage = 1;
          this.emptyInputs();
          this.loadData();
        })
        .catch((error) => {
          console.log(error);
          this.pageLoading = false;
        });
    },

    openCase(data) {
      this.$router.push({
        name: "Case_Law_Search_Cases_jurisdiction",
        params: {
          id: data,
        },
      });
    },

    loadData() {
      const me = this;
      function processData(data) {
        const DATA = data.data.data;
        const insert = me.searched_data.length > 0;
        me.pageLoading = false;
        me.rows = DATA.total;
        DATA.data.forEach(e => {
          const ID = me.searched_data.findIndex(old => old.id === e.id);
          try {
            e.statement = JSON.parse(e.statement).join(", ");
          } catch (error) {}

          if(ID > -1) {
            me.$set(me.searched_data, ID, e);
          } else if (insert) {
            me.searched_data.unshift(e);
          } else {
            me.searched_data.push(e);
          }

          if (e.completedPerCentage < 100) me.loop = true;
        });
      }

      loopUpdate(
        this,
        CaseSearch.GetCaseSearch,
        `&page=${this.currentPage}${
          this.searchQuery.length > 0
          ? `&statement=${this.searchQuery}`
          : ""
        }`,
        processData
      );
    },
  },

  watch: {
    currentPage(newPage, oldPage) {
      if (newPage == this.$route.query.page) return;

      this.pageLoading = true;
      this.searched_data = [];
      var queryObj = { page: newPage };
      if (this.searchQuery.length !== 0) {
        queryObj.statement = this.searchQuery;
      }
      this.$router.push({ query: queryObj });
      clearTimeout(this.loop);
      this.loadData();
    },

    juriNCourts: {
      handler(newVal, oldVal) {
        if (newVal == oldVal) {
          this.selectedCourt = [];
          this.searchCourtsQuery = "";
          this.selectedState = null;
        }
      },
      deep: true,
    },

    selectedState(newStateId) {
      if (newStateId === 'All') {
        this.searchCourtsQuery = "";
      } else {
        const newState = this.states.find((state) => state.id === newStateId);
        if (newState) {
          this.searchCourtsQuery = newState.name;
        } else {
          this.searchCourtsQuery = "";
        }
      }
    },  
  },

  created() {
    if (this.$route.query.page) {
      this.currentPage = parseInt(this.$route.query.page);
    }

    if (this.$route.query.statement) {
      this.searchQuery = this.$route.query.statement;
    }

    this.loadData();
  },

  beforeDestroy() {
    clearTimeout(this.loop);
  },
};
</script>
<style scoped>
/* table */
.table {
  border: none;
  background: white;
  table-layout: fixed;
}

tbody {
  display: block;
  max-height: auto;
  overflow-y: scroll;
}

thead {
  display: table;
  width: 100%;
  table-layout: fixed;
  overflow-x: auto;
  border-bottom: 0.1vw solid #ddd !important;
  background: white;
}

tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  overflow-x: auto;
  border: none;
  background: white;
}

td {
  border-bottom: 0.001px solid #ddd;
  border-top: none;
  vertical-align: top;
}

th {
  border: none;
  color: #094890;
}

.container_margin {
  padding: 2rem;
}

.spinner {
  color: #f08000 !important;
}

.spinner-border {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-vertical-align: -0.15em;
  --bs-spinner-border-width: 0.2em;
}

/* courts modal */
.court-list {
  max-height: 425px;
  overflow-y: auto;
  overflow-x: hidden;
}

.court_badge {
  width: max-content;
  border: none;
  background: #d2d2d2;
  color: black;
  border-radius: 20px;
  font-size: 14px;
  padding: 0.2rem 0.5rem;
}

.court_badge .material-icons-outlined {
  font-size: 14px;
  translate: 0.2rem 0.1rem;
}
</style>
